<template>
  <footer class="bg-black-500 py-10 text-white text-xs sm:text-base">
    <section class="lg:mb-6 lg:pb-6 lg:border-b border-solid border-black-100">
      <div class="container mx-auto px-5 lg:flex lg:justify-between">
        <div class="mb-6 pb-6 lg:mb-0 lg:pb-0 border-b border-solid border-black-100 lg:border-none">
          <nuxt-img
            src="/images/cameo/cameo.svg"
            alt="cameo"
            class="w-36"
          />
          <p class="opacity-50 font-medium mt-2 mb-6">
            We create fun, entertaining videos to aspire & influence people
          </p>

          <ul class="sm:inline-flex [&>li:first-child]:mb-4 font-semibold">
            <li class="mr-8">
              <NuxtLink
                to="/"
                @click.native="scrollTop()"
              >
                Home
              </NuxtLink>
            </li>
            <li>
              <NuxtLink
                to="/postproduction"
                @click.native="scrollTop()"
              >
                Post Production
              </NuxtLink>
            </li>
          </ul>
        </div>

        <div class="lg:flex">
          <div class="lg:mr-14">
            <p class="mb-3 lg:mb-8 font-semibold">
              Contact Us
            </p>
            <ul class="flex mb-6 lg:mb-0 [&>li]:mr-3 [&>li:last-child]:mr-0">
              <li>
                <a
                  href="mailto:thecameoproductions@gmail.com"
                  class="flex items-center justify-center w-8 h-8 sm:w-12 sm:h-12 bg-black-400 rounded-full"
                >
                  <nuxt-img
                    src="/images/icons/email.svg"
                    alt="email"
                    class="max-w-[12px] sm:max-w-full"
                  />
                </a>
              </li>
              <li>
                <a
                  href="https://wa.me/6285900100385"
                  target="_blank"
                  class="flex items-center justify-center w-8 h-8 sm:w-12 sm:h-12 bg-black-400 rounded-full"
                >
                  <nuxt-img
                    src="/images/icons/whatsapp.svg"
                    alt="whatsapp"
                    class="max-w-[12px] sm:max-w-full"
                  />
                </a>
              </li>
            </ul>
          </div>
          <div>
            <p class="mb-3 lg:mb-8 font-semibold">
              Follow Us
            </p>
            <ul class="flex mb-6 lg:mb-0 [&>li]:mr-3 [&>li:last-child]:mr-0">
              <li>
                <a
                  href="https://www.youtube.com/cameoproject"
                  target="_blank"
                  class="flex items-center justify-center w-8 h-8 sm:w-12 sm:h-12 bg-black-400 rounded-full"
                >
                  <nuxt-img
                    src="/images/icons/youtube.svg"
                    alt="youtube"
                    class="max-w-[12px] sm:max-w-full"
                  />
                </a>
              </li>
              <li>
                <a
                  href="https://www.tiktok.com/@cameoproject_"
                  target="_blank"
                  class="flex items-center justify-center w-8 h-8 sm:w-12 sm:h-12 bg-black-400 rounded-full"
                >
                  <nuxt-img
                    src="/images/icons/tiktok.svg"
                    alt="tiktok"
                    class="max-w-[12px] sm:max-w-full"
                  />
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/cameoproject/"
                  target="_blank"
                  class="flex items-center justify-center w-8 h-8 sm:w-12 sm:h-12 bg-black-400 rounded-full"
                >
                  <nuxt-img
                    src="/images/icons/instagram.svg"
                    alt="instagram"
                    class="max-w-[12px] sm:max-w-full"
                  />
                </a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/cameoprojects"
                  target="_blank"
                  class="flex items-center justify-center w-8 h-8 sm:w-12 sm:h-12 bg-black-400 rounded-full"
                >
                  <nuxt-img
                    src="/images/icons/facebook.svg"
                    alt="facebook"
                    class="max-w-[12px] sm:max-w-full"
                  />
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/cameoproject_"
                  target="_blank"
                  class="flex items-center justify-center w-8 h-8 sm:w-12 sm:h-12 bg-black-400 rounded-full"
                >
                  <nuxt-img
                    src="/images/icons/twitter.svg"
                    alt="twitter"
                    class="max-w-[12px] sm:max-w-full"
                  />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>

    <section class="container mx-auto px-5">
      <p class="text-xs sm:text-sm">
        Copyright © 2023 Cameo Project. All Rights Reserved
      </p>
    </section>
  </footer>
</template>

<script setup lang="ts">
const scrollTop = () => {
  window.scroll({ 
    top: 0, 
    left: 0, 
    behavior: 'smooth' 
  })
}
</script>