<template>
  <div class="bg-black-600 text-white">
    <Header />

    <main class="overflow-x-hidden">
      <slot />
    </main>

    <Footer />
  </div>
</template>

<script setup lang="ts">
import Header from '@/global/components/layouts/header.vue'
import Footer from '@/global/components/layouts/footer.vue'

const route = useRoute()

useHead({
  meta: [{ property: 'og:title', content: `Cameo - ${route.meta.title}` }]
})
</script>
