<template>
  <header class="fixed top-0 left-0 w-full z-30 after:absolute after:top-0 after:left-0 after:w-full after:h-full after:bg-black-600 after:opacity-100 after:z-[-1]">
    <section class="container mx-auto flex items-center justify-between px-5 py-4">
      <NuxtLink
        to="/"
        @click.native="scrollTop()"
      >
        <nuxt-img
          class="max-w-[100px] md:max-w-[140px] md:w-full"
          src="/images/cameo/cameo.svg"
          alt="cameo"
        />
      </NuxtLink>

      <ul class="list-menu pb-2">
        <li 
          v-for="(item) in listMenu"
          :key="item.title"
          :class="{'active': menuActive === item.path}"
        >
          <NuxtLink :to="item.path">
            {{ item.title }}
          </NuxtLink>
        </li>
      </ul>

      <ul class="hidden lg:flex">
        <li class="mr-6">
          <a
            href="https://www.youtube.com/cameoproject"
            target="_blank"
            class="flex items-center justify-center w-12 h-12 bg-black-400 rounded-full"
          >
            <nuxt-img
              src="/images/icons/youtube.svg"
              alt="youtube"
              class="opacity-30"
            />
          </a>
        </li>
        <li>
          <a
            href="https://www.instagram.com/cameoproject"
            target="_blank"
            class="flex items-center justify-center w-12 h-12 bg-black-400 rounded-full"
          >
            <nuxt-img
              src="/images/icons/instagram.svg"
              alt="instagram"
              class="opacity-30"
            />
          </a>
        </li>
      </ul>

      <button
        class="lg:hidden"
        @click="showMenu = !showMenu"
      >
        <nuxt-img
          src="/images/icons/burger-menu.svg"
          alt="menu"
        />
      </button>
    </section>
  </header>

  <sidebar :class="`fixed top-0 left-0 w-full h-full bg-red-300 z-40 transition-all duration-300 ${showMenu? 'translate-x-0' : 'translate-x-[200%]'}`">
    <section class="container mx-auto px-5 py-4">
      <div class="flex items-center justify-between">
        <NuxtLink
          to="/"
          @click.native="scrollTop()"
        >
          <nuxt-img
            class="max-w-[100px] w-[100px] md:max-w-[140px] md:w-full"
            src="/images/cameo/cameo-2.svg"
            alt="cameo"
          />
        </NuxtLink>
        <button @click="showMenu = !showMenu">
          <nuxt-img
            src="/images/icons/close.svg"
            alt="close"
          />
        </button>
      </div>

      <div class="w-full h-[1px] bg-black-300 my-8" />

      <ul class="[&>li]:mb-8 text-lg font-medium [&>li>a]:pb-2">
        <li
          v-for="item in listMenu"
          :key="`sidebar-${item.path}`"
        >
          <NuxtLink
            :to="item.path"
            :class="`relative ${menuActive === item.path? 'after:absolute after:bottom-0 after:left-0 after:w-full after:h-[2px] after:bg-black-500' : ''}`"
            @click.native="scrollTop()"
          >
            {{ item.title }}
          </NuxtLink>
        </li>
      </ul>

      <div class="w-full h-[1px] bg-black-300 mb-8 mt-14" />

      <ul>
        <li
          v-for="item in dataContacts"
          :key="`contact-${item.title}`"
          class="mb-4"
        >
          <span class="text-sm font-semibold">{{ item.title }}</span>
          <p class="text-xs opacity-75 mt-1">
            {{ item.subtitle }}
          </p>
        </li>
      </ul>
    </section>
  </sidebar>
</template>

<script setup lang="ts">
import dataContacts from '@/global/data/contacts.json'

defineComponent({
  name: 'Header'
})

const route = useRoute()

interface menu {
  title: String, path: string
}

const listMenu: menu[] = ref([
  { title: 'Home', path: '/' },
  { title: 'Post Production', path: '/postproduction' },
])

const menuActive: String = ref('')
watch(
  () => route.path,
  (val) => {
    menuActive.value = val
    showMenu.value = false
  }
)
onMounted(() => {
  menuActive.value = route.path
})

const showMenu: Boolean = ref(false)

const scrollTop = () => {
  window.scroll({ 
    top: 0, 
    left: 0, 
    behavior: 'smooth' 
  })
  showMenu.value = false
}
</script>